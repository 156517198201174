import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { SkillsBadges } from "./SkillsBadges"; 

export const Projects = () => {

  const projects = [
    {
      title: "Web Browser Calculator",
      description: "Web browser calculator that can perform basic arithmetic operations, made using HTML, CSS, and JavaScript.",
      imgUrl: projImg3,
      url: "https://aimanmadan.github.io/WebBrowserCalculator/",
    },
    {
      title: "Single Click Upload",
      description: "Utilizing Selenium WebDriver to interact with the BeatStars website, handling tasks such as logging in, uploading .wav files, filling in basic track information, and setting artwork.",
      imgUrl: projImg1,
      url: "https://www.youtube.com/watch?v=yqroCOb7b0k&ab_channel=AimanMadan",
    },
    {
      title: "CSUSM Data Gathering",
      description: "While working at CSUSM as a Lab Assistant, I was tasked with creating a tool that would gather data from the students and store it in a database for research purposes.",
      imgUrl: projImg2,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Projects</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Operational applications</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Languages and Tools</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Summary</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                              />
                            )
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <SkillsBadges />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>I have demonstrated expertise in creating innovative web scraping tools and automation projects using Selenium and Beautiful Soup. I have also developed various web applications utilizing HTML, CSS, JavaScript, and React JS, showcasing my front-end development skills.</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
